import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header.js";
import Footer from "../../components/footer/Footer.js";
import "./imageView.css";
import { baseUrl, config } from "../../network/constants/Constants";
import axios from "../../network/api";
import { Grid } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// function GalleryImageView = () => {

const GalleryImageView = (props) => {
  const [loading, setLoading] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const [modalShow, setModalShow] = React.useState(false);

  const [gallary, setGallary] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);

  const openImageFullscreen = (index) => {
    setSelectedIndex(index);
    handleShow(true);
  };

  useEffect(() => {
    fetchGallary();
  }, []);

  const fetchGallary = () => {
    setLoading(true);

    axios
      .get(baseUrl + "/webapp/gallery/")
      .then((response) => {
        setLoading(false);

        setGallary(response.data);
        // console.log(response.data);
      })
      // .catch((error) => {
      //   console.log(error);
      //   setLoading(true);

      // }

      .catch(function (error) {
        setLoading(true);
        console.log(error.data);
      });
  };
  return (
    <div>
      <Header />
      <Container>
        <div className="gallery-view">Image Gallery</div>
        <div className="gallery-div">
          <Grid
            className="gallarygrid"
            container
            spacing={{ xs: 2, md: 4, sm: 2 }}
            columns={{ xs: 12, sm: 13, md: 15 }}
          >
            {gallary &&
              gallary.map((gal, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} className="gallarysubgrid">
                    <img
                      src={gal.gallerybanner}
                      maxCount={1}
                      className="gallarycard img-fluid"
                      onClick={() => openImageFullscreen(index)}
                    />
                  </Grid>
                );
              })}
          </Grid>

          {/* modal section */}

          {gallary && (
            <Modal
              show={show}
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton onClick={handleClose}></Modal.Header>
              <Modal.Body>
                <Grid
                  className="modalgrid"
                  container
                  // spacing={{ xs: 2, md: 4, sm: 2 }}
                  // columns={{ xs: 12, sm: 13, md: 15 }}
                >
                  {/* {gallary &&
                  gallary.map((gal, index) => {
                    return ( */}
                  {console.log(
                    "gallarybanner: " + gallary[0].gallerybanner,
                    selectedIndex
                  )}
                  {/* {console.log("index: "+ selectedIndex)} */}

                  <Grid item xs={12} sm={12} md={12} className="modalsubgrid">
                    <img
                      // src={gal.gallerybanner}
                      src={gallary[selectedIndex].gallerybanner}
                      className="modalimage img-fluid"
                    />
                  </Grid>
                  {/* );
                  })} */}
                </Grid>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default GalleryImageView;
