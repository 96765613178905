
import React from 'react';
import Navigate from "./navigate/Navigation"
import './App.css'
function App() {
  return (
     
        
     <div className='App'>
      <Navigate/>
     </div>
    
  );
}

export default App;







