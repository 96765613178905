import React from "react";
import "./Footer.css";
import Grid from "@mui/material/Grid";
import Iframe from "react-iframe";
import Logo from "../../assets/images/logowithR.svg";
import Tm from "../../assets/images/TM.png";
import { GoMail } from "react-icons/go";
import { BiPhoneCall } from "react-icons/bi";
import { BiMap } from "react-icons/bi";
import Footerimg from "../../assets/images/footerimg.png";
import { Row, Col, Container } from 'react-bootstrap'
function Footer() {
  return (
    <div className="footerdiv ">
      <div className="">
        <div className="footerimg">
          <img src={Footerimg}></img>
        </div>
        <div className="footermain">
          <div className="foot_maincontent">
            <Grid container>
              <Grid item xs={12} md={6} sm={12} className="contentgrid">
                <div className="logodiv-main">
                  <div className="logodiv">
                    <img src={Logo} className="footerlogo"></img>

                    <div className="footerlogo-head">
                      <p className="footerlogo-head-name">Nuclear Engineering Inspection Service</p>
                      <p className="footerlogo-head-company">An ISO 9001:2015 Certified Company </p>
                    </div>
                  </div>
                </div>
                <div className="address">
                <div className="add-line">
                    <BiMap className="icon" />
                    <p>
                      2nd floor, NP Complex, Chungam Kanjany Road,<br></br>
                      Near Nirmala College Thrissur - 680 004<br></br>
                    </p>
                    <p></p>
                  </div>
                  <div className="add-line">
                    <BiPhoneCall className="icon" />
                    <a href="tel:+91 9207 305806">+91 9207 305806 </a>
                    &nbsp;|&nbsp;
                    <a href="tel:+91 7306 220800"> +91 7306 220800</a>
                    <p> </p>
                  </div>

                  <div className="add-line">
                    <GoMail className="icon" />
                    <p>
                      {" "}
                      <a href="mailto:info@nucleaengineering.in">
                        info@nucleaengineering.in&nbsp;|&nbsp;
                      </a><br />
                      <a href="mailto:engineeringnuclear83@gmail.com">
                        engineeringnuclear83@gmail.com
                      </a>
                    </p>
                  </div>
                </div>

              </Grid>
              <Grid item xs={12} md={6} sm={12} className="mapgrid">
                <Iframe
                  url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d980.6872054701342!2d76.196306629213!3d10.520439018091656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7efdfb2a2d519%3A0x6e912ff36923815d!2sNuclear%20engineering%20inspection%20service!5e0!3m2!1sen!2sin!4v1628752449585!5m2!1sen!2sin"
                  width="400px"
                  height="340px"
                  id="myId"
                  className="footermap"
                  display="initial"
                // position="relative"
                />
              </Grid>
            </Grid>
          </div>
          <Container>
            <Row>
              <Col lg={4}>
                <div className="address">
                  <div className="add-line">
                    <BiMap className="icon" />
                    <p>
                      {" "}
                      MBP Rd, Millenium Business Park,<br></br>
                      MIDC Industrial Area, Sector 1, Kopar Khairane,<br></br>
                      Navi Mumbai, Maharashtra 400710
                    </p>
                    <p></p>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="address">
                  <div className="add-line">
                    <BiMap className="icon" />
                    <p>
                      {" "}
                      Nungambakkam Post Office,  <br/> Chennai district , Tamil Nadu state, Sub office 
                     600034.
                    </p>
                    <p></p>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="address">
                  <div className="add-line">
                    <BiMap className="icon" />
                    <p>
                      {" "}
                      Pincode : 50819 ; Address : CBD21 / 204, <br></br>
                      Universal Building, International City,<br></br>
                      Dubai UAE ;
                    </p>
                    <p></p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <div className="footerlist">
            <ul>
              <li>
                DISCLAIMER THIRD PARTIES:<br></br>
                All product and company names are trademarks™ or registered®
                trademarks of their respective holders. Use of them does not
                imply any affiliation with or endorsement by them.
              </li>
              <li> <a href="policy.html">Read Our Privacy Policy</a></li>
            </ul>
          </div>
        </div>

        <div className="copyrightdiv">
          <div className="copyrightcontent">
            <p>
              Copyright © Nuclear Engineering Inspection Service 2015-2023 | All
              Right Reserved
            </p>
            <p>
              Made with ❤️ in India | Powered by{" "}
              <a href="https://nthindex.com">Nthindex Software Solutions LLP</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
