import React, { useEffect, useState } from "react";
import "./videoview.css";
import Header from "../../components/header/Header";
import { Container } from "react-bootstrap";
import { baseUrl } from "../../network/constants/Constants";
import axios from "../../network/api";
import { Col, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import Footer from "../../components/footer/Footer.js";

function GalleryVideoView() {
  const [loading, setLoading] = useState("");
  const [videos, setVideos] = useState("");
  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = () => {
    setLoading(true);

    axios
      .get(baseUrl + "/webapp/video/")
      .then((response) => {
        setLoading(false);
        setVideos(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  return (
    <div>
      <Header />
      <Container>
        <div className="gallery-view">Video Gallery</div>
        <Row className="gallery-video">
          {videos &&
            videos.map((vid, index) => {
              return (
                <Col lg={6} className="mt-3">
                  <ReactPlayer url={vid.youtube_link} className={"img-fluid"} />
                </Col>
              );
            })}
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default GalleryVideoView;
