import "./login.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap/";
import { TextField, Button } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import axios from "../../network/api";
import { baseUrl } from "../../network/constants/Constants";
// import AppConstants from "../AppContants";



function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const navigate = useNavigate();

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setEmailError(!regex.test(email));
    return regex.test(email);
  };

  const validatePassword = (password) => {
    setPasswordError(password.length < 8);
    return password.length >= 8;
  };

 

  const PostLogin = () => {
    setLoading(true);

    const payload = {
      email: email,
      password: password,
    };

    axios
      .post(baseUrl + "/auth/login/", payload)
      .then((response) => {
        console.log("Login successful:", response.data);
        setLoading(false);
        callDeleteAPI(response.data.token);
      })
      .catch((error) => {
        console.error("Login error:", error);
        setLoading(true);
      });
  };
 
  
  const callDeleteAPI =(token) => {


    setLoading(true);

    const config = {
        headers: {Authorization: `Token ${token}`}
      };
      console.log("token: "+token)
    axios
      .get(baseUrl + "/auth/delete-account/", config)
      .then((response) => {
        setLoading(false);
        navigate("../deleted", { replace: true });
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error + "errrorrriiii");
        setLoading(true);
      });
  };

  return (
    <section id="login">
      <div className="content">
        <div className="brand-container"></div>
        <Card className="login-card card mt-5 rounded-4">
          <div className="login-title">Login</div>

          <TextField
            id="email"
            className="mt-4 text-border"
            label="Email"
            variant="outlined"
            size="small"
            type="email"
            value={email}
            onChange={handleEmail}
            error={emailError}
            helperText={emailError ? "Invalid Email" : ""}
          />

          <TextField
            id="password"
            className="mt-4 text-border"
            label="Password"
            variant="outlined"
            size="small"
            type="password"
            value={password}
            onChange={handlePassword}
            error={passwordError}
            helperText={
              passwordError ? "Password must be at least 8 characters long" : ""
            }
          />

          <Button
            variant="contained"
            className="login-btn mt-5 mb-3"
            onClick={() => {
              if (validateEmail(email) && validatePassword(password)) {
                setLoading(true);
                PostLogin();
              }
            }}
          >
            Submit
          </Button>
          <div style={{display:"flex", justifyContent: "center" }}>
            <TailSpin
              className="mt-5"
              height="30"
              width="30"
              radius="1"
              color="rgb(239, 185, 85)"
              ariaLabel="loading"
              visible={loading}
            />
          </div>
        </Card>
      </div>

      <a href="tel:" className="contact">
        ⓘ Contact Us
      </a>
    </section>
  );
}

export default Login;
