import React from "react";
import Grid from "@mui/material/Grid";
import "./Universities.css";
import Black from "../../assets/images/black.png";
import Brunel from "../../assets/images/brunel.png";

import Leeds from "../../assets/images/leeds.png";

import Middlesex from "../../assets/images/middlesex.png";

import Uow from "../../assets/images/uow.png";

import Uws from "../../assets/images/uws.png";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import aru from "../../assets/images/aru.svg";
import nu from "../../assets/images/nu.svg";
import uoc from "../../assets/images/uoc.svg";
import uwt from "../../assets/images/uwt.svg";
import { display } from "@mui/system";
import jQuery, { nodeName } from "jquery";

const Universities = () => {
  // <script src="https://code.jquery.com/jquery-3.6.0.min.js"
  //           integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>

  return (
    
       <div className="universitydiv" id="universities">
      <div className="universityinner container">
        <Grid container>
          <Grid item xs={12}>
            <div className="universitieshead">Universities</div>
          </Grid>
        </Grid>
        <div className="logodiv">
          <Grid container className="logosection">
            <Grid item xs={12} md={4} sm={6}>
              <img src={Uws}></img>
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <img src={Uow}></img>
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <img src={Brunel}></img>
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <img src={Black}></img>
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <img src={Leeds}></img>
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <img src={Middlesex}></img>
            </Grid>
          </Grid>

          <div className="hiddenlogo" display="none">
            <Grid container className="logosection">
              <Grid item xs={12} md={4} sm={6} className="hiddenlogo" display="none">
                <img src={aru}></img>
              </Grid>
              <Grid item xs={12} md={4} sm={6} className="hiddenlogo" display="none">
                <img src={nu}></img>
              </Grid>
              <Grid item xs={12} md={4} sm={6} className="hiddenlogo" display="none">
                <img src={uoc}></img>
              </Grid>
              <Grid item xs={12} md={4} sm={6} className="hiddenlogo" display="none">
                <img src={uwt}></img>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="logoviewbtn">
          <Button variant="text" underline="none" className="universitybtn">
            View More
            <span>&nbsp;{">"}</span>
            <span className="color">{">"}</span>
          </Button>
        </div>
      </div>
    </div>

   
  );
};

//  View More Button For University Names
var uComplete;
jQuery(document).ready(function ($) {
  $(".logoviewbtn .universitybtn").click(function (e) {
    // alert("sdcas");
    $(".hiddenlogo:hidden").slice(0, 3).fadeIn(500, uComplete);
    if ($(".hiddenlogo:hidden").length < 1)
      $(this).fadeOut(1000, function () {
        // Fade in complete
        uComplete = true;
      });
  });
});
export default Universities;
