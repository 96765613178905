import React from "react";
import Grid from "@mui/material/Grid";
import Img from "../../assets/images/about.png";
import "./Aboutneis.css";
import ScrollAnimation from "react-animate-on-scroll";
const Aboutneis = () => {
  return (
    <section>
       <div className="aboutdiv container" id="about">
      <Grid container className="aboutgrid">
        <Grid item xs={12}>
          <div className="backimg">
            <div className="abouthead">About NEIS</div>
            <div className="aboutcontent">
              <p>
                {" "}
                We are glad to introduce ourselves as a Manpower supplier in
                NDT, welding,Safety, Coating, API and many other positions
                related to the Oil & Gas sector. Since 2015 we started our
                services in Training & Manpower supply now we are well known for
                our manpower services in both India & abroad. We have provided
                Manpower in INDIA & ABROAD for few of our Major clients.
              </p>
              <p>
                Our goal is to provide high-quality professional inspection
                courses that will prepare students to be more "industry ready"
                rather than just academically qualified. The vertically skilled
                specialists develop and deliver all of our technical courses.
                Our placement cell has established relationships with some of
                the country's most prestigious employers. Almost all technical
                and non-technical industries are establishing their own quality
                control departments in order to save time and money.{" "}
              </p>
              <p>
                A fresher would have no clear understanding about the sectors
                and various specialisations available to them when looking for
                work. Each company has its own specialisations in order to reach
                its objectives as quickly as possible and with precision. We
                provide high-quality professional courses to assist new students
                in overcoming obstacles, while assisting the industrial sector
                in filling openings with qualified personnel.
              </p>
            </div>
          </div>
          <div className="aboutimg">
            <div className="aboutimg-head">
              We offer<br></br>100 % placement assistance   
            </div>
            <img src={Img}></img>
          </div>
        </Grid>
      </Grid>
    </div>

    </section>
   
  );
};

export default Aboutneis;
