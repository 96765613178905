export const baseUrl = "https://admin.nuclearengineering.in";
// export const localData = {
//   token: "token",
//   username: "username",
//   email: "email",
// };

export const config = {
  headers: {
    // 'Authorization': "Token " + localStorage.getItem(localData.token),
    //  "Content-Type":"multipart/form-data",
    //  'X-CSRFTOKEN': CSRF_TOKEN,
    // 'Accept': 'application/json',
    // 'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',


    //  'accept': 'application/json',    "start": "react-scripts start",



  }
};

