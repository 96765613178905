import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import GalleryImageView from "../pages/galleryImageView/imageView.jsx";
import GalleryVideoView from "../pages/galleryVideoView/videoView.jsx";
import Login from "../pages/login/login.jsx";
import Otp from "../pages/otp/otp.jsx";
import Deleted from "../pages/deleted.jsx";
import AccountDeletion from "../pages/account_deletion.jsx";
function Navigation() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
        
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/galleryImageView" element={<GalleryImageView />} />
          <Route path="/galleryVideoView" element={<GalleryVideoView />} />
          <Route path="/deleted" element={<Deleted />} />
          <Route path="/account-deletion" element={<AccountDeletion />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Navigation;
