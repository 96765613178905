import React from "react";
import "./Visa.css";
import Grid from "@mui/material/Grid";
import Visaimg from "../../assets/images/visa.png";
import { TiTick } from "react-icons/ti";

<link
  href="https://fonts.googleapis.com/css?family=Electrolize"
  rel="stylesheet"
/>;

const Visa = () => {
  return (
    <section>
 <div className="visadiv container" id="visa">
      <Grid container className="visagrid">
        <Grid item xs={12}>
          <div className="visahead">Student Visa</div>
          <div className="visabanner">
            <div className="visabanner-head">STUDY IN EUROPE</div>
            <img src={Visaimg}></img>
          </div>
          <div className="visacontent">
            <p>
              European countries are an excellent choice for those interested in
              studying abroad. These nations not only offer well-developed
              educational systems and some of the world's best institutions, but
              also provide students with access to the European job market.
              Europe is a great place for students seeking knowledge and good
              job possibilities, with a wide range of nations to select from and
              access to the single market.
            </p>
            <p>
              Europe is home to several of the world's top universities. Many
              European nations also make an attempt to get international
              students to stay after graduation. That implies that if you choose
              to study in Europe, you are likely to receive a job offer and be
              able to remain for a longer period of time. It has a welcoming
              multi-cultural environment and high-quality universities in every
              field.
            </p>
          </div>
          <div className="visalist">
            <ul type="none">
              <li>
                <TiTick className="tik-color me-1" />
                Prestigious Universities
              </li>
              <li>
                <TiTick className="tik-color me-1" />
                Scholarships and free schooling are available (some countries)
              </li>
              <li>
                <TiTick className="tik-color me-1" />
                Opportunities exist in the sciences, the arts, and business.
              </li>

              <li>
                <TiTick className="tik-color me-1" />
                Environment that is multicultural
              </li>
              <li>
                <TiTick className="tik-color me-1" />
                Easy to travel and discover the whole continent
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
    </div>
    </section>
   
  );
};

export default Visa;
