import React,{useEffect,useState} from "react";
import "./Certifications.css";
import Grid from "@mui/material/Grid";
import Certificate from "../../assets/images/certificate.jpg";
import hanger from "../../assets/images/hanger.svg";
import Cardhanger from "./Cardhanger";
import { baseUrl,config } from "../../network/constants/Constants";
import axios from "../../network/api"
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";

const Certifications = () => {
  const [loading, setLoading] = useState("");

  const [certificates, setCertificates] = useState("");
  useEffect(() => {
    fetchCertificates();
  }, []);
  
  const fetchCertificates = () => {
    setLoading(true);

    axios
      .get(baseUrl + "/webapp/certificates/")
      .then((response) => {
        setLoading(false);

        setCertificates(response.data);
        // console.log(response.data);
      })
      // .catch((error) => {
      //   console.log(error);
      //   setLoading(true);

      // }
      
      .catch(function (error) {

        setLoading(true);
      //   switch (error.response.status) {
      //     case 503 :
      //         props.history.push('/503') //we will redirect user into 503 page 
      //         break
      //         case 400 :
      //           props.history.push('/400') 
      //           break
      //           case 401 :
      //             props.history.push('/401') 
      //             break 
      //              case 404:
      //             props.history.push('/404') 
      //             break  
      //             case 500 :
      //             props.history.push('/500') 
      //             break 
                 
                

      //     default :
      //         break
      // }
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      }

      );
      
      
  };

  return (
    <section>
       <div id="certifications" className="certificationsdiv ">
      <Grid container>
        <Grid item xs={12}>
          <div className="certificationhead"> Certifications</div>
        </Grid>
      </Grid>

      <div className="certificationsinner container">
        <Grid
          container
          spacing={{ xs: 2, md: 0 }}
          columns={{ xs: 4, sm: 8, md: 15 }}
          rowGap={5}
          className="grid-certificate"
        >
          {certificates&&certificates.map ((cer,index)=>{
            return(

              <Grid item xs={12} sm={4} md={4}>
              <div className="hangerdiv">
                <img src={hanger}></img>
              </div>
              <div className="cardcertificate">  
                <a
                  href={cer.pdf}
                  without
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <button type="submit">
                    <img src={cer.image} className="imagecertificate" />
                  </button>
                </a>
              </div>
            </Grid>
            )
          })}
        </Grid>
      </div>
      {loading&&
                <Box sx={{ display: "center", justifyContent: "center" }}>

      <CircularProgress />
      </Box>

}
    </div>
    </section>
   
  );
};

export default Certifications;
