import React, { useEffect } from "react";
import "./Header.css";
import jQuery, { nodeName } from "jquery";

import Logo from "../../assets/images/logowithR.svg";
import Tm from "../../assets/images/TM.png";
import Link from "@mui/material/Link";
import Logomain from "../../assets/images/mainlogo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FaFacebookSquare, FaLinkedin, FaYoutube } from "react-icons/fa";
import NavDropdown from "react-bootstrap/NavDropdown";
import { BsInstagram } from "react-icons/bs";
import { useLocation } from "react-router-dom";
{
  /* <link
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
  rel="stylesheet"
  integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
  crossorigin="anonymous"
/>; */
}
{
  /* <script
  src="https://code.jquery.com/jquery-3.6.0.min.js"
  integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4="
  crossorigin="anonymous"
></script>; */
}

function Header() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          setTimeout(function () {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 100);
        }
      }, 100);
    }
  }, [pathname, hash, key]);
  return (
    <section id="home">
      <header id="main">
        <div className="headerdiv">
          <div className="firstnav-section">
            <Container className="div">
              <div className="phone">
                <p>Reach Us :</p>
                <a href="tel:9037187800" class="float1" target="_blank">
                  &nbsp;+91 9207 305806
                </a>
                {/* <div className="socialmedia"> */}
                <div className="socialmedia">
                  <a
                    href="https://www.facebook.com/profile.php?id=100054340638207"
                    target="_blank"
                  >
                    <FaFacebookSquare />
                    &nbsp;
                  </a>
                  <a
                    href="https://www.instagram.com/engineering_nuclear/"
                    target="_blank"
                  >
                    <BsInstagram />
                    &nbsp;
                  </a>
                  <a
                    href="https://www.linkedin.com/in/neis-nuclear-engineering-inspection-service-1ba21389/"
                    target="_blank"
                  >
                    <FaLinkedin />
                    &nbsp;
                  </a>
                  <a
                    href="https://youtu.be/qbl00YlXi78"
                    target="_blank"
                  >
                    <FaYoutube />
                  </a>
                </div>
              </div>
            </Container>
          </div>

          <div className="head-centersection">
            <img src={Logo} className="headerlogo"></img>

            <p className="head-title">Nuclear Engineering Inspection service</p>
            <p className="head-subtitle">An ISO 9001:2015 Certified Company </p>
          </div>

          <nav
            class="navbar navbar-expand navbar-light bg-light card-5"
            id="stickyClass"
          >
            <div class="container-fluid d-flex justify-content-around">
              <div class="collapse navbar-collapse">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li>
                    <div class="brand-name">
                      <a href="/#homepage">
                        <img
                          src={Logomain}
                          alt="NEIS Logo"
                          class="list-inline-item nav-brand"
                        />
                        <p class="list-inline-item c-font-28 c-font-md-22 text-center">
                          NEIS
                        </p>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item active">
                    <Link
                      href="/#courses"
                      underline="none"
                      aria-current="page"
                      class="nav-link"
                    >
                      Our Courses
                    </Link>
                  </li>
                  <li class="nav-item ">
                    <Link
                      href="/#gallaryneis"
                      underline="none"
                      aria-current="page"
                      class="nav-link"
                    >
                      Gallery
                    </Link>
                  </li>
                  <li class="nav-item ">
                    <Link href="/#visa" underline="none" class="nav-link">
                      Student Visa
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link href="/#clients" underline="none" class="nav-link">
                      Our Clients
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link href="/#about" underline="none" class="nav-link">
                      About Us
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      href="/#certifications"
                      underline="none"
                      class="nav-link"
                    >
                      Certifications
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      href="/#testimonials"
                      underline="none"
                      class="nav-link"
                    >
                      Testimonials
                    </Link>
                  </li>

                  <li class="nav-item">
                    <Link href="/#contact" underline="none" class="nav-link">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </section>
  );
}

jQuery(function ($) {
  window.addEventListener("scroll", function () {
    let header = document.querySelector("nav");
    header.classList.toggle("nav-sticky", window.scrollY > 360);
  });

  // navigation Bar make Sticky while scrolling
  // const nav = document.querySelector("#main");
  // const topOfNav = nav.offsetTop;
  // const element = document.getElementById("stickyClass");

  // function fixNav() {
  //   if (window.scrollY >= topOfNav) {
  //     element.classList.add("nav-sticky");
  //   } else {
  //     element.classList.remove("nav-sticky");
  //   }
  // }
  // window.addEventListener("scroll", fixNav);
  // Code for changing active link on clicking
  var btns = $("navbar-nav > navlink");
  if (btns.length != 0) {
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");

        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }
  }

  // Changing active link on Scrolling
  $(window)
    .scroll(function () {
      var windscroll = $(window).scrollTop();
      if (windscroll >= 100) {
        $("section").each(function (i) {
          // The number at the end of the next line is how pany pixels you from the top you want it to activate.
          if ($(this).position().top <= windscroll - 360) {
            $(".navbar li.active").removeClass("active");
            $(".navbar li").eq(i).addClass("active");
          }
        });
      } else {
        $(".navbar li.active").removeClass("active");
        $(".navbar li:nth-child(2)").addClass("active");
      }
    })
    .scroll();
});
export default Header;
