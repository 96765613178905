import React from "react";
import "./Cardhanger.css";
import hanger from "../../assets/images/hanger.svg";
function Cardhanger(props) {
  return (
    <div>
      <div className="hangerdiv">
        <img src={hanger}></img>
      </div>
      <div className="cardcertificate">
        {/* <button type="submit"  onClick="window.open('pdfdummy')"> */}

        <a href={props.pdf} without rel="noopener noreferrer" target="_blank">
          <button type="submit">
            <img src={props.image} className="imagecertificate img-fluid" />
          </button>
        </a>
      </div>
    </div>
  );
}

export default Cardhanger;
