import React, { useEffect, useState } from "react";
import "./Testimonials.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import Carousel from "react-bootstrap/Carousel";
import Quotation from "../../assets/images/quotation.png";
import "bootstrap/dist/css/bootstrap.min.css";
import Avatar from "@mui/material/Avatar";
import profile from "../../assets/images/profile.webp";
import axios from "../../network/api";
import { baseUrl, config } from "../../network/constants/Constants";
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css"
  integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor"
  crossorigin="anonymous"
/>;

export const Testimonials = () => {
  const [loading, setLoading] = useState("");

  const [testimonials, setTestimonials] = useState("");
  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = (props) => {
    setLoading(true);

    axios
      .get(baseUrl + "/webapp/testimonial/")
      .then((response) => {
        setLoading(false);

        setTestimonials(response.data);
        // console.log(response.data);
      })


      // .catch((error) => {
      //   setLoading(true);

      //   console.log(error);
      // }
      // .catch(httpErrorHandler)



        .catch(function (error) {

        setLoading(true);
        switch (error.response.status) {
          case 503 :
              props.history.push('/503') //we will redirect user into 503 page 
              break
              case 400 :
                props.history.push('/400') 
                break
                case 401 :
                  props.history.push('/401') 
                  break 
                   case 404:
                  props.history.push('/404') 
                  break  
                  case 500 :
                  props.history.push('/500') 
                  break 
                 
                

          default :
              break
      }
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      }

      );
  };

  // function httpErrorHandler(error) {
  //   if (error === null) throw new Error('Unrecoverable error!! Error is null!')
  //   if (axios.isAxiosError(error)) {
  //     //here we have a type guard check, error inside this if will be treated as AxiosError
  //     const response = error?.response
  //     const request = error?.request
  //     const config = error?.config //here we have access the config used to make the api call (we can make a retry using this conf)
  
  //     if (error.code === 'ERR_NETWORK') {
  //       console.log('connection problems..')
  //     } else if (error.code === 'ERR_CANCELED') {
  //       console.log('connection canceled..')
  //     }
  //     if (response) {
  //       //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
  //       const statusCode = response?.status
  //       if (statusCode === 404) {
  //         console.log('The requested resource does not exist or has been deleted')
  //       } else if (statusCode === 401) {
  //         console.log('Please login to access this resource')
  //         //redirect user to login
  //       }
  //       else if(statusCode===400)
  //       {
  //         console.log('Bad Request')
  //       }
  //       else if(statusCode===500)
  //       {
  //         console.log('Internal Server Error')
  //       }
  //     } else if (request) {
  //       //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
  //     }
  //   }
  //   //Something happened in setting up the request and triggered an Error
  //   console.log(error.message)
  // }


  return (
    <section>
      <div className="carouseldiv container" id="testimonials">
        <div className="carouselimg">
          <img src={Quotation}></img>
        </div>
        <div className="testimonialshead">Testimonials</div>

        <Carousel touch={true} variant="dark" className="c">
          {testimonials &&
            testimonials.map((test, index) => {
              return (
                <Carousel.Item interval={5500} key={index}>
                  <div className="carousel-data">{test.message}</div>
                  <div className="avatardiv">
                    <Avatar
                      alt="Remy Sharp"
                      // src={profile}
                      src={test.image}
                      sx={{ width: 70, height: 70 }}
                    />
                    <div className="testcaption">
                      <Carousel.Caption>
                        <h6> {test.author}</h6>
                        <p>{test.designation}</p>
                      </Carousel.Caption>
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
        </Carousel>

        {/* <Carousel touch={true} variant="dark" className="c">
        <Carousel.Item interval={5500}>
          <div className="carousel-data">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do e a
            lacus vestibulum sed. Enim lobortis scelerisque fermentum dui
            faucibus in ornare. Risus ultricies tristique nulla aliquet. Turpis
            massa sed elementum tempus egestas. Fringilla est ullamcorper eget
            nulla facilisi. Habitant morbi tristique senectus et netus et
            malesuada.
          </div>
          <div className="avatardiv">
            <Avatar
              alt="Remy Sharp"
              src={profile}
              sx={{ width: 70, height: 70 }}
            />
            <div className="testcaption">
              <Carousel.Caption>
                <h6>Renil K</h6>
                <p>Lorem ipsu adipiscing elit.</p>
              </Carousel.Caption>{" "}
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={5500}>
          <div className="carousel-data">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. At
            tempor commodo ullamcorper a lacus vestibulum sed. Enim lobortis
            scelerisque fermentum dui faucibus in ornare. Risus ultricies
            tristique nulla aliquet. esuada.
          </div>
          <div className="avatardiv">
            <Avatar
              alt="Kemy Sharp"
              src={profile}

              sx={{ width: 70, height: 70 }}
            />

            <div className="testcaption">
              <Carousel.Caption>
                <h5>Kenil K</h5>
                <p>Lorem ipsu adipiscing elit.</p>
              </Carousel.Caption>{" "}
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={5500}>
          <div className="carousel-data">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. At
            eget nulla facilisi. Habitant morbi tristique senectus et netus et
            malesuada.
          </div>
          <div className="avatardiv">
            <Avatar
              alt="Jemy Sharp"
              src={profile}

              sx={{ width: 70, height: 70 }}
            />
            <div className="testcaption">
              <Carousel.Caption>
                <h5>Jonil K</h5>
                <p>Lorem ipsu adipiscing elit.</p>
              </Carousel.Caption>{" "}
            </div>
          </div>
        </Carousel.Item>
      </Carousel> */}

        {loading && (
          <Box sx={{ display: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
      </div>
    </section>
  );
};
