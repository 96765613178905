import React from "react";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import "./Clients.css";
import Ellips from "../../assets/images/ellipse.png";
import Rect from "../../assets/images/rectangle.png";
import Button from "@mui/material/Button";
import { display } from "@mui/system";
import jQuery, { nodeName } from "jquery";
import { Row, Col } from "react-bootstrap";
function Clients() {
  return (
    <section>
      <div className="clientdiv container" id="clients">
        <Grid container>
          <Grid item xs={12}>
            <div className="clienthead">Our Major Clients</div>
          </Grid>
        </Grid>

        <Grid
          container
          className="chipgrid"
          spacing={{ xs: 2, md: 1, sm: 2 }}
          columns={{ xs: 12, sm: 10, md: 12 }}
          // style={{ display: "flex", justifontent: "flex-start" }}
        >
          <Row className="client-row">
            <Grid item xs={12}>
              <div className="subhead">UAE</div>
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="SIEVERT" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="ALL MASOOD" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="GPTI" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="LAMPRELL" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="NDTCCS" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="ADNOC TRANSASIA" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="UNITED GLOBAL" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="AVN QUALITY" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="INTERFACE" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="SIGMA OILFIELD" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="BAKER OILFIELD" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="BETA NDT" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="ARIES MARINE" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="ADNOC" className="chipstyle" />
            </Grid>
          </Row>
          <Row className="client-row">
            <Grid item xs={12}>
              <div className="subhead">SAUDI</div>
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="SEM" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="NSH" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="ALHOTY" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="SIEVERT" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="GQCCO" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="AXIS" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="POWER-MECH" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="GLOBAL INSPECTIONS" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="NDTCS" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="STAR NDT" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="INSPECTION TECH" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="MIT" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="SSD" className="chipstyle" />
            </Grid>
            
          </Row>

          <Row className="client-row">
            <Grid item xs={12}>
              <div className="subhead">KUWAIT</div>
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="KNPC" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="NBTC" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="ISCO" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="INTEREX" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="EQUATE" className="chipstyle" />
            </Grid>
            <Grid item xs={6} md={2} sm={3} className="visagrid">
              <Chip label="KSA" className="chipstyle" />
            </Grid>
          </Row>
        </Grid>

        <div className="hiddenclients" display="none">
          <Grid
            container
            className="chipgrid"
            spacing={{ xs: 2, md: 1, sm: 2 }}
            columns={{ xs: 12, sm: 10, md: 12 }}
            style={{ display: "flex", justifontent: "flex-start" }}
          >
            <Row className="client-row">
              <Grid item xs={12} className="hiddenclients" display="none">
                <div className="subhead">QATAR</div>
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="SGS" className="chipstyle" />
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="SIEVERT" className="chipstyle" />
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="GIS" className="chipstyle" />
              </Grid>
            </Row>
            <Row className="client-row">
              <Grid item xs={12} className="hiddenclients" display="none">
                <div className="subhead">OMAN</div>
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="SIEVERT" className="chipstyle" />
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="ALHOTY" className="chipstyle" />
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="VELOSI" className="chipstyle" />
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="ARIES GLOBAL" className="chipstyle" />
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="GTIS" className="chipstyle" />
              </Grid>
            </Row>
            <Row className="client-row">
              <Grid item xs={12} className="hiddenclients" display="none">
                <div className="subhead">BAHRAIN</div>
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="ALHOTY" className="chipstyle" />
              </Grid>
            </Row>
          </Grid>
        </div>

        <div className="hiddenclients" display="none">
          <Grid
            container
            className="chipgrid"
            spacing={{ xs: 2, md: 1, sm: 2 }}
            columns={{ xs: 12, sm: 10, md: 12 }}
            // style={{ display: "flex", justifontent: "flex-start" }}
          >
            <Row className="client-row">
              <Grid item xs={12} className="hiddenclients" display="none">
                <div className="subhead">IRAN</div>
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="SIEVERT" className="chipstyle" />
              </Grid>
            </Row>
            <Row className="client-row">
              <Grid item xs={12} className="hiddenclients" display="none">
                <div className="subhead">IRAQ</div>
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="SIEVERT" className="chipstyle" />
              </Grid>
            </Row>
            <Row className="client-row">
              <Grid item xs={12} className="hiddenclients" display="none">
                <div className="subhead">AJAR BAJAN</div>
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="SIEVERT" className="chipstyle" />
              </Grid>
            </Row>
            <Row className="client-row">
              <Grid item xs={12} className="hiddenclients" display="none">
                <div className="subhead">MANGOLIA</div>
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="B.V-SIEVERT" className="chipstyle" />
              </Grid>
            </Row>
            <Row className="client-row">
              <Grid item xs={12} className="hiddenclients" display="none">
                <div className="subhead">SINGAPORE</div>
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="ROY SCAN" className="chipstyle" />
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="JASSCAN" className="chipstyle" />
              </Grid>
            </Row>
          </Grid>
        </div>

        <div className="hiddenclients" display="none">
          <Grid
            container
            className="chipgrid "
            spacing={{ xs: 2, md: 1, sm: 2 }}
            columns={{ xs: 12, sm: 10, md: 12 }}
          >
            <Row className="client-row">
              <Grid item xs={12} className="hiddenclients" display="none">
                <div className="subhead">INDIA</div>
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sm={3}
                className="visagrid hiddenclients"
                display="none"
              >
                <Chip label="150+ Companies" className="chipstyle" />
              </Grid>
            </Row>
          </Grid>
        </div>

        <div className="clientviewbtn">
          <Button variant="text" underline="none" className="clientsbtn">
            View More <span>&nbsp;{">"}</span>
            <span className="color">{">"}</span>
          </Button>
        </div>
      </div>
    </section>
  );
}

//  View More Button For Country Names
var complete;
jQuery(document).ready(function ($) {
  $(".clientviewbtn .clientsbtn").click(function (e) {
    //alert("sdcas");
    $(".hiddenclients:hidden").slice(0, 3).fadeIn(500, complete);
    if ($(".hiddenclients:hidden").length < 1)
      $(this).fadeOut(1000, function () {
        // Fade in complete
        complete = true;
      });
  });
});
export default Clients;
