import React, { useState, useRef } from "react";
import "./Contact.css";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Watsup from "../../assets/images/whatsapp.png";
const Contact = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [msg, setMsg] = useState("");
  const nameInput = useRef(null);
  const phoneInput = useRef(null);
  const subjectInput = useRef(null);
  const msgInput = useRef(null);

  function contactUs(event) {
    if (name.length == 0) {
      nameInput.current.focus();
    } else if (phone.length == 0) {
      phoneInput.current.focus();
    } else if (subject.length == 0) {
      subjectInput.current.focus();
    } else if (msg.length == 0) {
      msgInput.current.focus();
    } else {
      let url =
        " https://api.whatsapp.com/send?phone=919207305806&text=" +
        "Hi there, this is " +
        name +
       
        "%0aSubject: " +
        subject +
        
        "%0amessage: " +
        msg;
      // console;
      window.open(url);

    }
    setName("");setSubject("");setMsg("");setPhone("");
event.preventDefault();
  }

  return (
    <section>
       <div className="contactdiv container" id="contact">
      <Grid container>
        <Grid item xs={12}>
          <div className="contacthead">Contact Us</div>
        </Grid>
      </Grid>

      <div className="contactbox">
        <Grid container className="gridcontactbox">
          <Grid item md={3} xs={12} sm={12}>
            <div className="watsuphead">
              Whatsapp Us
              <span>&nbsp;{">"}</span>
              <span className="color">{">"}</span>

              <div>
                <img alt="" src={Watsup} className="watsupimg"/>
              </div>
            </div>
          </Grid>
          <Grid item md={9} xs={12} sm={12}>
            <div className="textfielddiv">
              <TextField
                      inputProps={{ style: { color: 'black'}}}

                className="textfield"
                // id="outlined-basic"
                label="Name"
                // variant="outlined"
                onChange={(event) => setName(event.target.value)}
                inputRef={nameInput}
                value={name}
              />
              {/* <br></br> */}
              <br></br>
              <TextField
                className="textfield"
                // id="outlined-basic"
                label="Phone"
                // variant="outlined"
                onChange={(event) => setPhone(event.target.value)}
                inputRef={phoneInput}
                value={phone}
              />
              {/* <br></br> */}
              <br></br>
              <TextField
                className="textfield"
                // id="outlined-basic"
                label="Subject"
                // variant="outlined"
                onChange={(event) => setSubject(event.target.value)}
                inputRef={subjectInput}
                value={subject}
              />
              <br></br>
              {/* <br></br> */}
            </div>
          </Grid>

          <Grid item md={12} xs={12} sm={12}>
            <TextField
              className="textfieldmsg"
              // id="outlined-basic"
              label="Message"
              // variant="outlined"
              multiline
              rows={6}
              color="primary"
              fullWidth
              onChange={(event) => setMsg(event.target.value)}
              inputRef={msgInput}
              value={msg}
            />
          </Grid>

          <Grid item xs={12} md={12} sm={12}>
            <div className="sendbtndiv">
              <Button
                variant="contained"
                className="sendbtn"
                onClick={contactUs}
              >
                Send
                <span>&nbsp;{">"}</span><span className="color" >{">"}</span>

               
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>

    </section>
   
  );
};

export default Contact;
