import React, { useState, useEffect } from "react";
import { FaOptinMonster } from "react-icons/fa";
import "./Gallaryneis.css";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import img1 from "../../assets/images/coursethree.png";
import img2 from "../../assets/images/coursetwo.png";
import img3 from "../../assets/images/courseone.png";
import jQuery, { nodeName } from "jquery";
import { baseUrl, config } from "../../network/constants/Constants";
import axios from "../../network/api";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

// import Button from "@mui/material/Button";

const Gallaryneis = (props) => {
  const [loading, setLoading] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const [modalShow, setModalShow] = React.useState(false);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [gallary, setGallary] = useState("");

  const openImageFullscreen = (index) => {
    setSelectedIndex(index);
    handleShow(true);
  };

  useEffect(() => {
    fetchGallary();
  }, []);

  const fetchGallary = () => {
    setLoading(true);

    axios
      .get(baseUrl + "/webapp/gallery/")
      .then((response) => {
        setLoading(false);

        setGallary(response.data);
        // console.log(response.data);
      })
      // .catch((error) => {
      //   console.log(error);
      //   setLoading(true);

      // }

      .catch(function (error) {
        setLoading(true);
        console.log(error.data);
      });
  };

  const navigate = useNavigate();

  function HandleNavigation() {
    navigate("../galleryImageView");
  }
  return (
    <section>
      <div id="gallaryneis" className="gallaryouter ">
        <div className="gallaryinner container">
          <div className="gallaryhead"> Image Gallery</div>
          <div className="gallery-div">
            <Grid
              className="gallarygrid"
              container
              spacing={{ xs: 2, md: 4, sm: 2 }}
              columns={{ xs: 12, sm: 13, md: 15 }}
            >
              {gallary &&
                gallary.map((gal, index) => {
                  if (index < 6) {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        className="gallarysubgrid"
                      >
                        <img
                          src={gal.gallerybanner}
                          onClick={() => openImageFullscreen(index)}
                          className="gallarycard img-fluid"
                        />
                      </Grid>
                    );
                  }
                })}
            </Grid>

            {/* modal section */}

            {gallary && (
              <Modal
                show={show}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton onClick={handleClose}></Modal.Header>
                <Modal.Body>
                  <Grid
                    className="modalgrid"
                    container
                    // spacing={{ xs: 2, md: 4, sm: 2 }}
                    // columns={{ xs: 12, sm: 13, md: 15 }}
                  >
                    {/* {gallary &&
                  gallary.map((gal, index) => {
                    return ( */}
                    {console.log(
                      "gallarybanner: " + gallary[0].gallerybanner,
                      selectedIndex
                    )}
                    {/* {console.log("index: "+ selectedIndex)} */}

                    <Grid item xs={12} sm={12} md={12} className="modalsubgrid">
                      <img
                        // src={gal.gallerybanner}
                        src={gallary[selectedIndex].gallerybanner}
                        className="modalimage img-fluid"
                      />
                    </Grid>
                    {/* );
                  })} */}
                  </Grid>
                </Modal.Body>
              </Modal>
            )}

            {/* hidden image */}
            {/* 
            <div className="hiddenimage" display="none">
              <Grid
                className="gallarygrid"
                container
                spacing={{ xs: 2, md: 4, sm: 2 }}
                columns={{ xs: 12, sm: 13, md: 15 }}
              >
                {gallary &&
                  gallary.map((gal, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        className="hiddenimage gallarysubgrid"
                        display="none"
                      >
                        <img src={gal.gallerybanner} className="gallarycard" />
                      </Grid>
                    );
                  })}
              </Grid>
            </div> */}
          </div>

          <div className="gallaryviewbtn">
            <Button
              variant="text"
              underline="none"
              className="gallarybtn"
              onClick={HandleNavigation}
            >
              View More
              <span>&nbsp;{">"}</span>
              <span className="color">{">"}</span>
            </Button>
          </div>
        </div>
        {loading && (
          <Box sx={{ display: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
      </div>
    </section>
  );
};
//  View More Button For University Names
// var uComplete;
// jQuery(document).ready(function ($) {
//   $(".gallaryviewbtn .gallarybtn").click(function (e) {
//     // alert("sdcas");
//     $(".hiddenimage:hidden").slice(0, 2).fadeIn(500, uComplete);
//     if ($(".hiddenimage:hidden").length < 1)
//       $(this).fadeOut(1000, function () {
//         // Fade in complete
//         uComplete = true;
//       });
//   });
// });
export default Gallaryneis;
