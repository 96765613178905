import React, { useEffect, useState } from "react";
import "./Videoplayer.css";
// import Videod from "../../assets/video/video.mp4";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import Grid from "@mui/material/Grid";
import { baseUrl } from "../../network/constants/Constants";
import axios from "../../network/api";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Col, Row } from "react-bootstrap";
import Link from "@mui/material/Link";
import jQuery, { nodeName } from "jquery";
import Button from "@mui/material/Button";

import ReactPlayer from "react-player";
// import ReactPlayer from 'react-player/lazy'

// import ReactPlayer from 'react-player/youtube'

import "video-react/dist/video-react.css";
import { Player } from "video-react";
import { useNavigate } from "react-router-dom";

function Videoplayer() {
  const [loading, setLoading] = useState("");
  const [videos, setVideos] = useState("");
  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = () => {
    setLoading(true);

    axios
      .get(baseUrl + "/webapp/video/")
      .then((response) => {
        setLoading(false);
        setVideos(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const navigate = useNavigate();

  function HandleNavigation() {
    navigate("../galleryVideoView")
  }

  return (
    <div id="video" className="container">
      <div className="videohead">Video Gallary </div>

      <Row>
        {videos &&
          videos.map((vid, index) => {
            if(index < 6){
            return (
              <Col lg={6} className="mt-3">
                <ReactPlayer url={vid.youtube_link} className={"img-fluid"} />
              </Col>
            );
            }
          })}
      </Row>


      <div className="gallaryviewbtn">
            <Button variant="text" underline="none" className="gallarybtn"  onClick={HandleNavigation}>
              View More
              <span>&nbsp;{">"}</span>
              <span className="color">{">"}</span>
            </Button>
          </div>
     
      {loading && (
        <Box sx={{ display: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}

export default Videoplayer;
