import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import Aboutneis from "./aboutneis/Aboutneis";
import "./Home.css";
import { Testimonials } from "./testimonials/Testimonials";
import Client from "./clients/Clients";
import Course from "./courses/Course";
import Visa from "./visa/Visa";
import Contactus from "./contact/Contact";
import Universities from "./universities/Universities";
import Footer from "../components/footer/Footer";
import Gallaryneis from "./gallaryneis/Gallaryneis";
import Certifications from "./certifications/Certifications";
import Download from "../pages/download/Download";
import Videoplayer from "./videoSection/Videoplayer";

function Home() {
  return (
    <section id="homepage">
      <body>
        <div id="header">
          <Header />
        </div>
        <div className="maindiv" id="home">
          <div id="courses">
            <Course />
          </div>
          <div id="gallaryneis">
            <Gallaryneis />
          </div>
          <div id="video">
            <Videoplayer />
          </div>
          <div id="visa">
            <Visa />
          </div>
          <div id="universities">
            <Universities />
          </div>
          <div id="clients">
            <Client />
          </div>
          <div id="about">
            <Aboutneis />
          </div>
          <div id="certifications">
            <Certifications />
          </div>
          <div id="testimonial">
            <Testimonials />
          </div>
          <div id="contact">
            <Contactus />
          </div>
          <div id="download">
            <Download />
          </div>
        </div>
        <div id="footer">
          <Footer />
        </div>
      </body>
    </section>
  );
}

export default Home;
