import React, { useState } from "react";
import "./Course.css";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Courseone from "../../assets/images/courseone.png";
import Coursetwo from "../../assets/images/coursetwo.png";
import Coursethree from "../../assets/images/coursethree.png";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

import Modal from "react-bootstrap/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Course = () => {
  const [showoil, setShowOil] = useState(false);

  const handleCloseOil = () => setShowOil(false);
  const handleShowOil = () => setShowOil(true);

  const [showndt, setShowNdt] = useState(false);

  const handleCloseNdt = () => setShowNdt(false);
  const handleShowNdt = () => setShowNdt(true);
  const [showmech, setShowMech] = useState(false);

  const handleCloseMech = () => setShowMech(false);
  const handleShowMech = () => setShowMech(true);

  return (
    <section>
      <div className="coursediv container" id="courses">
        <div className="a">
          <Grid container>
            <Grid item xs={12}>
              <div className="coursehead"> Our Courses</div>
              <div className="coursecontent">
                <p>
                  {" "}
                  Our goal is to provide high-quality professional inspection
                  courses that will prepare students to be more "industry ready"
                  rather than just academically qualified. The vertically
                  skilled specialists develop and deliver all of our technical
                  courses. Our placement cell has established relationships with
                  some of the country's most prestigious employers. Almost all
                  technical and non-technical industries are establishing their
                  own quality control departments in order to save time and
                  money.
                </p>
                <p>
                  A fresher would have no clear understanding about the sectors
                  and various specialisations available to them when looking for
                  work. Each company has its own specialisations in order to
                  reach its objectives as quickly as possible and with
                  precision. We provide high-quality professional courses to
                  assist new students in overcoming obstacles, while assisting
                  the industrial sector in filling openings with qualified
                  personnel.
                </p>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="cardgrid">
          <Grid
            container
            className="gcontainer"
            spacing={{ xs: 2, md: 2 }}
            //   columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} md={4} sm={6}>
              <Card className="card" onClick={handleShowMech}>
                <CardMedia
                  className="cardimg"
                  component="img"
                  alt="green iguana"
                  image={Coursethree}
                />
                <CardContent className="card-content">
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className="cardtitle ms-2"
                  >
                    QA / QC Mechanical
                  </Typography>
                  <Link href="#" underline="none" className="viewbtn ms-2">
                    View More
                    <span>&nbsp;{">"}</span>
                    <span className="color">{">"}</span>
                  </Link>
                </CardContent>
                <CardActions></CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <Card className="card" onClick={handleShowNdt}>
                <CardMedia
                  className="cardimg"
                  component="img"
                  alt="green iguana"
                  image={Coursetwo}
                />
                <CardContent className="card-content">
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className="cardtitle ms-2 "
                  >
                    QA / QC With Advanced NDT
                  </Typography>
                  <Link href="#" underline="none" className="viewbtn ms-2">
                    View More<span>&nbsp;{">"}</span>
                    <span className="color">{">"}</span>
                  </Link>
                </CardContent>
                <CardActions></CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <Card className="card" onClick={handleShowOil}>
                <CardMedia
                  className="cardimg"
                  component="img"
                  alt="green iguana"
                  image={Courseone}
                />
                <CardContent className="card-content">
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className="cardtitle ms-2"
                  >
                    QA / QC Offshore Oil & Gas
                  </Typography>
                  <Link href="#" underline="none" className="viewbtn ms-2">
                    View More<span>&nbsp;{">"}</span>
                    <span className="color">{">"}</span>
                  </Link>
                </CardContent>
                <CardActions></CardActions>
              </Card>
            </Grid>
          </Grid>
        </div>

        <div>
          <Modal
            show={showoil}
            onHide={handleCloseOil}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>QA /QC Oil And Gas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                {" "}
                This course is intended for applicants who want to begin or
                further their careers in the oil and gas sectors. From both a
                practical and theoretical standpoint, this course examines the
                geology, exploration, drilling, production, reservoir
                engineering and management, distribution, and transportation of
                oil and gas. There are three streams in the oil and gas sector.
              </p>
              <p>
                Upstream (Exploration, Drilling, Completion and Production of
                crude) Midstream (Storage, Initial processing and Transportation
                of crude) Downstream (Refining and production of Petroleum
                products)
              </p>
              <p>
                NEIS is one of Kerala's top training schools, offering QA/QC
                courses. The courses are created with present needs in mind.
                This course is designed for welding and piping inspection
                professionals, such as technicians, inspectors, and engineers,
                who are called upon to observe, oversee, or perform visual
                welding inspection and pipe inspection operations.
              </p>
              <p>
                Practical aspects are focused by ensuring that theoretical
                training is supported with practical sessions focusing on the
                finer points of welding and piping inspections, all under the
                supervision of a trained instructor.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <a href="#contact" underline="none">
                <Button
                  variant="primary"
                  underline="none"
                  onClick={handleCloseOil}
                >
                  Contact US{" "}
                </Button>
              </a>
            </Modal.Footer>
          </Modal>
        </div>
        <div>
          <Modal
            show={showndt}
            onHide={handleCloseNdt}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>QA / QC With Advanced NDT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                For the inspection of industrial plants, advanced ultrasonic
                testing procedures are in high demand. NEIS would happily assist
                in the provision of advanced NDT training in order to transmit
                expertise of Nuclear Engineering Inspection Services, including
                Phased Array Ultrasonic Testing (PAUT).{" "}
              </p>

              <p>
                {" "}
                We provide highly qualified advanced NDT teachers with
                comprehensive practical and theoretical expertise gathered over
                many years.{" "}
              </p>

              <p>
                {" "}
                Our courses allow you to obtain a formal NDT qualification or
                customised training based on customer demands, such as training
                for managers and design engineers, or QA/QC staff to understand
                and review NDT inspection data.
              </p>

              <p>
                {" "}
                NEIS is one of Kerala's top training schools, offering QA/QC
                courses. The courses are created with present needs in mind.
                This course is designed for welding and piping inspection
                professionals, such as technicians, inspectors, and engineers,
                who are called upon to observe, oversee, or perform visual
                welding inspection and pipe inspection operations.
              </p>

              <p>
                {" "}
                Practical aspects are focused by ensuring that theoretical
                training is supported with practical sessions focusing on the
                finer points of welding and piping inspections, all under the
                supervision of a trained instructor.{" "}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <a href="#contact">
                <Button variant="primary" onClick={handleCloseNdt}>
                  Contact US{" "}
                </Button>
              </a>
            </Modal.Footer>
          </Modal>
        </div>
        <div>
          <Modal
            show={showmech}
            onHide={handleCloseMech}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>QA / QC Mechanical</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                {" "}
                Mechanical engineers that work in QA/AC have a wide range of
                options. To assure the quality of the products, a variety of
                approaches are employed. These approaches assist them in
                detecting anomalies at an early stage, allowing them to identify
                a solution.
              </p>

              <p>
                NEIS is one of Kerala's top training schools, offering QA/QC
                courses. The courses are created with present needs in mind.
                This course is designed for welding and piping inspection
                professionals, such as technicians, inspectors, and engineers,
                who are called upon to observe, oversee, or perform visual
                welding inspection and pipe inspection operations.
              </p>

              <p>
                {" "}
                Practical aspects are focused by ensuring that theoretical
                training is supported with practical sessions focusing on the
                finer points of welding and piping inspections, all under the
                supervision of a trained instructor.{" "}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <a href="#contact">
                <Button variant="primary" onClick={handleCloseMech}>
                  Contact Us
                </Button>
              </a>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </section>
  );
};

export default Course;
