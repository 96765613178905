import React from "react";
import "./Download.css";
import Grid from "@mui/material/Grid";
import splash from "../../assets/images/splash.svg";
import googleplay from "../../assets/images/googleplay.svg";
import appstore from "../../assets/images/appstore.svg";

function Download() {
  return (
    <div className="downloaddiv container" id="download">
      <Grid container>
        <Grid item xs={12}>
          <div className="downloadhead">Download Application</div>
        </Grid>
        <Grid item xs={12}>
          <div className="downloadsubhead">
            Our app is now available on Google Play & Appstore
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xl={6} xs={12} sm={6} md={6}>
          <Grid container>
            <Grid item xl={6} xs={12} sm={6} md={6}>
              <div className="downloadphone">
                <img src={splash}></img>
              </div>
            </Grid>
            <Grid
              item
              xl={6}
              xs={12}
              sm={6}
              md={6}
              className="logodiv-download"
            >
              <div className="downloadlogo1">
                <a href="https://play.google.com/store/apps/details?id=com.neis.eduapp">
              
                  <img src={googleplay} />
                </a>
              </div>
              <div className="downloadlogo2">
                <a href="https://apps.apple.com/in/app/neis-edu-app/id1662438812">
             
                  <img src={appstore} />
                </a>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xl={6} xs={12} sm={6} md={6}>
          <div>
            <ul type="none">
              <li className="disclaimer">DISCLAIMER</li>
              <li className="subdisclaimer">
                1) Apple, the Apple logo and Appstore Badge are trademarks of
                Apple Inc., registered in the U.S. and other countries.
              </li>
              <li className="subdisclaimer">
                2) Google Play and the Google Play logo are trademarks of Google
                LLC.
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Download;
